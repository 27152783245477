<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 活动价格
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <div class="mr9">
                    <el-button
                        type="primary"
                        icon="el-icon-plus"
                        class="handle-del mr10"
                        @click="addguestroommetnod"
                    >新增活动价格</el-button>
                </div>
                <div class="mr9">
                    <el-button
                        type="primary"
                        icon="el-icon-back"
                        class="handle-del mr10"
                        v-on:click="back"
                    >返回</el-button>
                </div>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column prop="price" label="活动价格" align="center"><template #default="scope">￥{{ scope.row.price }}</template></el-table-column>
                <el-table-column prop="start_time" label="开始时间" :formatter="timestampToTime" align="center"></el-table-column>
                <el-table-column prop="end_time" label="结束时间" :formatter="timestampToTime2" align="center"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" :formatter="timestampToTime4" align="center"></el-table-column>
                <el-table-column prop="update_time" label="更新时间" :formatter="timestampToTime3" align="center"></el-table-column>
                <el-table-column label="操作" width="280" align="center">
                    <template #default="scope">
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" class="red" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :page-size="query.pageSize"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
            
            <!-- 添加弹出框 -->
            <el-dialog title="添加活动价格" v-model="addVisible" width="40%">
                <el-form :model="addmonthData" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
                     <el-form-item label="开始时间" prop="start_time">
                         <el-date-picker
                              v-model="addmonthData.start_time"
                              type="date"
                              placeholder="选择日期"
                              format="yyyy 年 MM 月 dd 日"
                              value-format="yyyy-MM-dd">
                         </el-date-picker>
                    </el-form-item>
                     <el-form-item label="结束时间" prop="end_time">
                         <el-date-picker
                              v-model="addmonthData.end_time"
                              type="date"
                              placeholder="选择日期"
                              format="yyyy 年 MM 月 dd 日"
                              value-format="yyyy-MM-dd">
                         </el-date-picker>
                    </el-form-item>
                     <el-form-item label="活动价格" prop="price">
                         <el-input v-model="addmonthData.price" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addmenuFun('addmonthData')">确定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="40%">
            <el-form :model="editmonthData" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
                     <el-form-item label="开始时间" prop="start_time">
                         <el-date-picker
                              v-model="editmonthData.start_time"
                              type="date"
                              placeholder="选择日期"
                              format="yyyy 年 MM 月 dd 日"
                              value-format="yyyy-MM-dd">
                         </el-date-picker>
                    </el-form-item>
                     <el-form-item label="结束时间" prop="end_time">
                         <el-date-picker
                              v-model="editmonthData.end_time"
                              type="date"
                              placeholder="选择日期"
                              format="yyyy 年 MM 月 dd 日"
                              value-format="yyyy-MM-dd">
                         </el-date-picker>
                    </el-form-item>
                     <el-form-item label="活动价格" prop="price">
                         <el-input v-model="editmonthData.price" placeholder="请输入活动价格"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit('editmonthData')">确定</el-button>
                    </span>
                </template>
        </el-dialog>
    </div>
</template>

<script>
import { get_room_act_price_list,post_room_act_list } from "../../api/index";
export default {
    name: "basetable",
    data() {
        return {
            query: {
                room_id: this.$route.query.id,
            },
            addmonthData:{
               type:"add",
               room_id:this.$route.query.id,
               start_time:this.start_time,
               end_time:this.end_time,
               price:this.price,
            },
            editmonthData:{
               room_id:this.$route.query.id,
               start_time:this.start_time,
               end_time:this.end_time,
               price:this.price,
            },
            editID:"",
               // 表单验证
            rules: {
               price: [
                    { required: true, message: "请输入月份价格", trigger: "blur" },
               ],
               start_time: [
                    { required: true, message: "请选择开始时间", trigger: "change" },
               ],
               end_time: [
                    { required: true, message: "请选择结束时间", trigger: "change" },
               ],
            },
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            addVisible:false
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        
        getData() {
            get_room_act_price_list(this.query).then(res => {
               
                this.tableData = res.data;
                this.pageTotal = res.data.count;
            });
        },
        // 删除操作
        handleDelete(index,row) {
            var addhotelData = {
                type: "delete",
                id: row.id,
            };
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            }).then(() => {
            post_room_act_list(addhotelData).then((res) => {
                if (res.code == 1) {
                    this.$message.success(res.msg);
                    this.getData();
                }else{
                    this.$message.error(res.msg);

                }
            });
          this.tableData.splice(index, 1);
            })
            .catch(() => {});
        },
        //新增月份价格
        addguestroommetnod() {
             this.addVisible = true 
        },
        
     //创建时间格式化
        timestampToTime(row) {
          if(row.start_time == 0){
               return "-"
          }else{
               var date = new Date(row.start_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
               var Y = date.getFullYear() + '-';
               var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
               var D = (date.getDate() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               var h = (date.getHours() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var m = (date.getMinutes() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var s = (date.getSeconds() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               return Y+M+D+h+m+s;
          }
        },
        timestampToTime2(row) {
          if(row.end_time == 0){
               return "-"
          }else{
               var date = new Date(row.end_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
               var Y = date.getFullYear() + '-';
               var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
               var D = (date.getDate() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               var h = (date.getHours() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var m = (date.getMinutes() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var s = (date.getSeconds() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               return Y+M+D+h+m+s;
          }
        },
     //创建时间格式化
        timestampToTime3(row) {
          if(row.update_time == 0){
               return "-"
          }else{
               var date = new Date(row.update_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
               var Y = date.getFullYear() + '-';
               var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
               var D = (date.getDate() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               var h = (date.getHours() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var m = (date.getMinutes() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var s = (date.getSeconds() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               return Y+M+D+h+m+s;
          }
        },
     //创建时间格式化
        timestampToTime4(row) {
          if(row.create_time == 0){
               return "-"
          }else{
               var date = new Date(row.create_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
               var Y = date.getFullYear() + '-';
               var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
               var D = (date.getDate() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               var h = (date.getHours() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var m = (date.getMinutes() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ':';
               var s = (date.getSeconds() +1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + ' ';
               return Y+M+D+h+m+s;
          }
        },
        
        addmenuFun() {
          console.log(this.addmonthData);
          post_room_act_list(this.addmonthData).then((res) =>{
               console.log(res);
               if(res.code == 1){
                    this.$message.success(res.msg);
                    this.addVisible = false
                    this.getData()
               }else{
                    this.$message.error(res.msg)
               }
          })
        },
        // 编辑操作
        handleEdit(index, row) {
            this.editVisible = true;
            this.editmonthData = row;
            this.editID = row.id
        },
        // 保存编辑
        saveEdit() {
          var editmonthData={
               type:"edit",
               id:this.editID,
               room_id:this.$route.query.id,
               start_time:this.editmonthData.start_time,
               end_time:this.editmonthData.end_time,
               price:this.editmonthData.price,
            };
            console.log(this.editmonthData);
            
          post_room_act_list(editmonthData).then((res) => {
          if(res.code == 1){
               this.$message.success(res.msg);
               this.editVisible = false;
               this.getData()
          }else{
               this.$message.error(res.msg);

          }
          })
        },
        // 分页导航
        handlePageChange(val) {
          //   this.$set(this.query, "pageIndex", val);
            this.getData();
        },
          back() {
               this.$router.go(-1); //返回上一层
          },
    }
};
</script>

<style scoped>
.title_01{
     margin-bottom: 20px;
}
.handle-box {
    margin-bottom: 77px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    margin-right: 10px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr9{float: left;}
.mr10 {
    margin-right: 10px;
    float: right;
}
.mr11{
    float: right;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
